@import '~antd/dist/antd.less';

.ant-image-preview-operations{
  background: #000000 !important;
}

.version-container{
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.4;
  margin-right: 20px;
}

iframe {
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d3d3;
  border-radius: 5px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.rc-virtual-list-scrollbar-show{
  display: block!important;
}
@primary-color: #07517E;