#root {
  height: 100%;
  font-family: 'Inter', sans-serif;

}

.user-name {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000;
  margin-left: 10px;
  @media (max-width: 992px) {
    display: none;
  }

  b {
    font-weight: 600;
  }

}
.hello-user{
  padding: 20px 50px;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}
.infoText{
  margin: 27px 30px 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  width: 200px;
  color: rgba(68, 68, 68, 1);
  .title {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
  }
  .value {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-bottom: 20px;
  }
}
.logout{
  padding: 0 6px !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
  color: rgba(68, 68, 68, 1)
}

.breadcrumbs {
  padding-left: 32px;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000;
    text-transform: uppercase;

  }

  .breadcrumbs-item {
    color: #777777;
    font-size: 9px;
    font-weight: 200;
    line-height: 11px;
    letter-spacing: 0.03em;
    text-align: left;
    text-transform: uppercase;
    margin: 0 3px;

    &:after {
      content: ">";
      margin-left: 6px;
    }

    &:last-child:after {
      content: "";
    }
  }
}

.body {
  background-color: #f5f5f5;
}

.container-fluid {
  padding: 30px;

  @media (max-width: 576px) {
    padding: 5px;
  }
}

.simplebar {
  height: calc(100vh - 70px);
}

#layout-wrapper {
  height: calc(100vh - 70px);
  overflow: auto;
}

.main-content {
  height: 100%;
}

.ant-input-number-input,
.ant-input-number.ant-input-number-in-form-item{
    width: 100% !important;
}

.content{
    display: flex;
    flex-direction: column;
    overflow: auto;
    #layout-wrapper{
        //flex: 1;
    }
}

.menu-content {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  .content {
    flex: 1 1 auto;
    background-color: #f5f5f5;

    #page-topbar {
      background-color: #ffffff;
      box-shadow: 0 0 50px 0 #00000014;
      position: relative;
    }
  }
}

.simplebar {
  overflow: auto;
}

.navbar-brand-box {
  height: 70px;
  width: 300px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 30px 0 0;

  &>a{
    transition: visibility ease-in-out 0.3s;
    visibility: visible;
  }
  .logo-svg.move-logo {
    padding: 27px 30px 0 60px;
    opacity: 0;

  }

  .logo-svg {
    padding: 27px 30px 0 30px;
    opacity: 1;
    transition: padding ease-in-out 0.3s, opacity ease-in-out 0.2s;
  }
}

.navbar-brand-box.hide {
  max-width: 0;
  & > a{
    visibility: hidden;
  }

  .logo-expand {
    transform: translate(-2px, -50%) rotate(180deg);

  }
}

.navbar-brand-box {
  position: relative;
  max-width: 300px;
  transition: max-width ease-in-out 0.3s;
  z-index: 2;
  background: #FFFFFF;

  .logo-expand {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transition: transform ease-in-out 0.3s;
    transform: translate(50%, -50%) rotate(0);
    width: 40px;
    border-radius: 50%;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);

  }
}

@media (max-width: 992px) {
  #menu.vertical-menu.opened {
    display: block;

  }
  #menu.vertical-menu {
    position: absolute;
    display: block;
  }
}

#menu.hide {
  max-width: 0;
}

#menu {
  position: relative;
  z-index: 99;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  width: 300px;
  transition: max-width ease-in-out 0.3s;
  max-width: 300px;
  color: #000000;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .arrow {
    position: absolute;
    top: 24px;
    right: 30px;
  }

  a.active:before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #0085FF !important;
  }

  .active {
    cursor: pointer;

    & > span {
      color: #0085FF !important;
    }
  }

  .level-1 {
    .level-1-item.expand-item {
      max-height: 800px;
    }
    .level-1-item {
      padding: 15px 0 15px 30px;
      max-height: 36px;
      transition: max-height ease-in-out .3s;
      overflow: hidden;

      .main-title {
        display: flex;
        align-items: center;
        padding: 0;
        cursor: pointer;

        .main-title-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 0 6px;
          text-transform: uppercase;
          color: #000000;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .level-2 {
        .level-2-item:after {
          content: "";
          width: 7px;
          height: 1px;
          position: absolute;
          top: 26px;
          left: 7px;
          background-color: rgba(0, 0, 0, 0.06);
        }

        .level-2-item:before {
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 6px;
          background-color: rgba(0, 0, 0, 0.06);
        }

        .level-2-item:last-child:before {
          height: 27px;
        }

        .level-2-item.expand-item {
          max-height: 250px;

          .arrow {
            transform: rotate(180deg);
          }
        }

        .level-2-item {
          max-height: 36px;
          transition: max-height ease-in-out .3s;
          overflow: hidden;
          position: relative;
          padding-left: 20px;

          a.title-2:hover:before {
            content: "";
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #0085FF;
          }

          .title-2:hover {
            cursor: pointer;

            .title-text-2 {
              color: #0085FF;
            }
          }

          .title-2 {
            padding: 15px 0 0 0;

            .title-text-2 {
              margin-left: 6px;
              color: #444444;
              font-size: 12px;
              font-weight: 600;
            }
          }

          .level-3 {
            .level-3-item:after {
              content: "";
              width: 7px;
              height: 1px;
              position: absolute;
              top: 22px;
              left: 7px;
              background-color: rgba(0, 0, 0, 0.06);
            }

            .level-3-item:before {
              content: "";
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 6px;
              background-color: rgba(0, 0, 0, 0.06);
            }

            .level-3-item:last-child:before {
              height: 23px;
            }

            .arrow {
              transform: rotate(0deg);
            }

            .level-3-item.expand-item {
              max-height: 160px;

              .arrow {
                transform: rotate(180deg);
              }
            }

            .level-3-item {
              padding-left: 20px;
              position: relative;
              max-height: 36px;

              a.title-3:hover:before {
                content: "";
                width: 3px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: #0085FF;
              }

              .title-3:hover {
                cursor: pointer;

                .title-text-3 {
                  color: #0085FF;
                }
              }

              .title-3 {
                padding: 10px 0 0 0;

                .title-text-3 {
                  color: #444444;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }
          }

          .level-4 {
            .level-4-item:after {
              content: "";
              width: 7px;
              height: 1px;
              position: absolute;
              top: 22px;
              left: 7px;
              background-color: rgba(0, 0, 0, 0.06);
            }

            .level-4-item:before {
              content: "";
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 6px;
              background-color: rgba(0, 0, 0, 0.06);
            }

            .level-4-item:last-child:before {
              height: 23px;
            }

            .level-4-item.expand-item {
              max-height: 160px;

              .arrow {
                transform: rotate(180deg);
              }
            }

            .level-4-item {
              padding-left: 20px;
              position: relative;

              a.title-4:hover:before {
                content: "";
                width: 3px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: #0085FF;
              }

              .title-4:hover {
                cursor: pointer;

                .title-text-4 {
                  color: #0085FF;
                }
              }

              .title-4 {
                padding: 10px 0 0 0;

                .title-text-4 {
                  color: #444444;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 768px) {
  .mobile-menu-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
  }
}

.ant-spin.ant-spin-lg.ant-spin-spinning {
  max-height: unset !important;
}

.user-column-value{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;

  color: #0085FF;

}

.priority-text{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
}
.priority-text.high{
  color: #FF0000;
}
.priority-text.medium{
  color: #FF8A00;
}
.priority-text.low{
  color: #5ACC00;
}

.uppercase-text{
  text-transform: uppercase;
}

.menu-icon{
  width: 16px;
}
