// 
// tabs.scss
// 

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0085FF!important;
}

.ant-tabs-tab:hover {
  color: #0085FF!important;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  padding: 15px 0;
}
.ant-tabs-content {
  padding: 15px 0;
}

.ant-tabs-ink-bar {
  background-color: #0085FF!important;
}

.ant-switch-checked {
  background-color: #0085FF!important;
}